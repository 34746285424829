.dhs{
  font-size: 10px
}

.heading2{
    padding: 50px 0;
    background-color: #000000;
  }
  .heading2 h3{
    font-size: 40px;
    color: #ffffff;
    font-weight: 200;
  }
  
  .otherimage{
    height: 100px;
    width: 100px;
  }

  /* others */
  .others{
    padding: 50px 0;
    background-color: #000000;
  }

  .other-content{
    padding: 50px 0;
    background-color: #171717;
    margin: 0px 6%;
  }
  
  .others ul li .item2{
    display: flex;
    align-items: center;
  }
  .others ul li{
    padding: 20px 0;
  }
  .others ul{
    list-style: none;
  }
  .others .item2 h5{
    color: #fff;
    font-size: 20px;
    font-weight: 300;
    padding: 0 20px;
    text-align: left;
    width: 40%;
  }
  .others .item2 p{
    color: #d99448;
    font-size: 16px;
    padding: 0 20px;
    width: 30%;
    text-align: right;
    margin-bottom: 0;
  }
  .others .item2 .line{
    height: 1px;
    width: 50%;
    background-color: #585858
   }
  