.navbar-nav .nav-link:hover {
  color: yellow; 
}


#custom-navbar {
  position: absolute;
  top: 5;
  left: 0;
  right: 0;
  z-index: 1000;
  overflow: hidden;
   background-color: transparent;
    margin-right: 20px;
    margin-left: 20px;
}

#navbarNav{
  margin-top: 3rem ;
}


.navbar-brand img {
    width: 123px;
  }
  .navbar-nav {
    align-items: center;
  }

 
  .navbar .navbar-nav .nav-link {
    color: #ffffff;
    font-size: 16px;
    text-transform: uppercase;
    padding: 10px 15px;
  }
  @media screen and (max-width: 767px) {   

    #navbarNav{
      height: 400px;
      border-radius: 40px;
      backdrop-filter: blur(5px)
    
    }
  }

  .icon-white {
    filter: invert(100%);
  }
 

  @media screen and (min-width: 768px) { 
    
    
    .navbar-brand img {
      width: 233px;
      height: 110px;
    }
    
  
    .navbar-brand {
      margin-right: 0;
      padding: 0 16px;
    }
    .navbar-brand {
      margin-right: auto;
    }
  
    .navbar-nav {
      margin-left: auto; 
    }

  
  }


  
  .navbar-nav .nav-link:hover {
    transform: matrix(1.5);
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    border-bottom: 2px solid;
    color: yellow;
    box-shadow: 0 0 6px 0 3px rgba(83, 246, 2, 0.3);
    
      }