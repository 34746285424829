.gallery{
    background-color: #000000;
    padding: 50px 0;
  }
  .gallery img{
    width: 100%;
    display: flex;
    padding: 10px 0;
  }
  .gallery p{
    color: #999999;
    font-size: 20px;
    margin: 0;
    font-weight: 200;
  }
  .gallery h3{
    color: #fff;
    font-size: 40px;
    font-weight: 300;
    padding-bottom: 50px;
  }
  .gallery .button4{
    padding: 10px 35px;
    border: 2px solid #ffffff;
    text-transform: uppercase;
    font-size: 16px;
    /* font-weight: 600; */
    color: #ffffff;
    text-decoration: none;
    display: inline-block;
    margin-top: 50px;
  }
  
  