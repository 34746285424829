.top-header{
    background-color: #222121;
    padding: 3px 10px;
}
.top-header ul{
    margin: 0;
    padding: 0;
}
.top-header ul li {
    display: inline-block;
    
}
.top-header ul li a{
    text-decoration: none;
    color: #a8a8a8;
    margin: 0 5px;
    font-size: 15px;
    font-weight: 400;
}

  

  
  