.banner{
    width: 100%;
    min-height: 600px;
    background-position: center;
    background-size: cover;
    background-blend-mode: multiply; 
     min-height: calc(600px - 72px);
}
.banner .texts{
    display: inline-flex;
    align-items: center;
    min-height: calc(600px - 72px);
    color: #fff;
    text-align: center;
    width: 100%;
    justify-content: center;
}
.banner img{
 width: 100%;
}


#menu{
    background: url("../../../../public/images/Layer-87.jpg");
    background-repeat: no-repeat;
    width: 100%;
    background-position: top;
    /* background-size: contain; */
  }

  #slidermenu{
  height: 553px;
  width: 100%;
  background-position: center center;
  background-size: cover;
  }
  

button {
    padding: 10px 35px;
    border: 2px solid #fff;
    background-color: transparent;
    text-transform: uppercase;
    font-size: 16px;
    display: inline-block;
    font-family: inherit;
    color: #fff;
   }
   .banner p{
    font-size: 25px;
    text-transform: uppercase;
    margin: 0;
   }
   .banner h3{
    font-size: 60px;
    padding-bottom: 20px;
    color: #ffffff;
    font-weight: 200;
   }
   
   @media screen and (max-width: 768px) {
    .banner .texts p {
        font-size: 6vw;
    }
}


@media screen and (max-width: 1305px) and (min-width: 768px) {
    #slidermenu{
      height: 550px;
      width: 100%;
    }
    #menu{
      background-repeat: no-repeat;
      width: 100%;
      background-position: initial;
      background-size: cover;
    }
  
    .banner .texts h1 {
      font-size: 6vw;
    }
  
    .banner .texts {
      top: 63%; 
    }
    
    .banner .texts h1 {
        font-size: 6vw;
      }
      .banner .texts h1 {
        font-size: 6vw;
      }
    
      .banner .texts p {
        font-size: 2vw;
      }

      .banner p {
        font-size: 3vw; /* Adjust paragraph font size for smaller screens */
      }
    
      .banner h3 {
        font-size: 6vw; /* Adjust heading font size for smaller screens */
      }
  }