

   .banner {
    position: relative;
    width: 100vw; 
    height: 100vh; 
    overflow: hidden;
  }
  
  
  
  .video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media (max-width: 576px) {
    
 #home{
  background-repeat: no-repeat;
  width: 100%;
  background-position: top;
  object-fit: contain;
}

  #sliderhome{
    max-width: 100%;
    height: auto;
    padding-top: 5vw;
    background-position: center center;
    background-size: cover;
}

  }
  
  .banner .texts {
    /* position: absolute;
    top: 50%;
    left: 50%; */
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #fff;
    z-index: 1;
  }
  
  .banner .texts h1 {
    font-size: 4vw;
    font-weight: 700;
    text-transform: uppercase;
  }
  
  .banner .texts p {
    font-size: 1.5vw; 
    color: #fff;
    margin: 0;
    text-transform: uppercase;
  }

  
  @media screen and (max-width: 768px) {
    .banner .texts h1 {
      font-size: 6vw;
    }
    .banner .texts p {
      font-size: 2vw; 
    }
    button {
      padding: 3vw 8vw;
      border: 1vw solid #fff; 
      /* font-size: 2vw;  */
    }
  }
  .banner .texts img {
    max-width: 65%; 
    margin-top: 150px;
    height: auto; 
  } 

  @media screen and (max-width: 768px) {
    .banner #textss{
        top: 43%;
    }


  }


 


