   
/* key-dishes */
.key-dishes{
    padding: 50px 0;
    background-color: #c5c4b1;
  }
  .key-dishes img{
  width: 100%;
  display: flex;
  padding-bottom: 20px;
  }
  .key-dishes h3{
    font-size: 30px;
    font-weight: 300;
    color: #000000;
  }
  .key-dishes p{
    font-size: 16px;
    color: #000000;
    padding: 0 50px;
    text-align: justify;
  
  }