
@font-face {
  font-family: 'Aileron';
  src: url('../public/Aileron-Regular.otf') format('opentype');
  /* You can add font-weight and font-style here if needed */
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
    font-family: "Aileron",;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */




@font-face {
  font-family: 'Aileron-SemiBold';
  src: url('../public/Aileron-SemiBold.otf') format('opentype');
  /* You can add font-weight and font-style here if needed */
}

@font-face {
  font-family: 'Aileron-Heavy';
  src: url('../public/Aileron-Heavy.otf') format('opentype');
 
}


@font-face {
  font-family: 'Aileron-UltraLight';
  src: url('../public/Aileron-UltraLight.otf') format('opentype');
} 


@font-face {
  font-family: 'Aileron-Heavy';
  src: url('../public/Aileron-Heavy.otf') format('opentype');
 
}


@font-face {
  font-family: 'Aileron-Thin';
  src: url('../public/Aileron-Thin.otf') format('opentype');
 
}

@font-face {
  font-family: 'Aileron-Light';
  src: url('../public/Aileron-Light.otf') format('opentype');
 
}

.A-Regular{
  font-family: 'Aileron';
}

.A-Thin{
  font-family: 'Aileron-Thin';
}

.A-Light{
  font-family: 'Aileron-Light', sans-serif;
}


.A-Semi-Bold{
  font-family: 'Aileron-SemiBold', sans-serif;
}



.A-Heavy{
  font-family: 'Aileron-Heavy', sans-serif;
  font-size: 32px;
}



.A-ULight{
  font-family: 'Aileron-UltraLight', sans-serif;
}

.swal2-loading {
  width: 100px;
  height: 100px;
  border-radius: 30px
}

 .sliderButton:hover{
    background-color: yellow;
     color: black;
     border-color: yellow;
   }

