
.seasonal{
  padding: 50px 0;
  background-color: #000000;
}
.seasonal .barbeque{
 width: 100%;
 display: flex;
}

.seasonal .seafish img{
  width: 130px;
  height: 76px;
  display: flex;
  gap: 10px;
  padding: 0 10px;
}
.seasonal .seafish {
  display: flex;
  background-color: #fff;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.seasonal  ul{
  padding: 0;
  list-style: none;
}
.seasonal .seafish .line1{
  border-top: 1px solid black;
  padding-top: 5px;
  height: 10px;
  height: 1px;
  width: 50%;
}
.seasonal .seafish .line1:before{
    content: '';
    border-top: 1px solid black;
    display: block;
    width: 100%;
    height: 10px;
}
/* .seasonal .seafish .line1 {
  border-top: 1px solid black;
  width: 50%;
} */
.seasonal h3{
  font-size: 40px;
  color: #ffffff;
  font-weight: 200;
  padding-bottom: 30px;
}
.seasonal .seafish .menu-name {
  flex-grow: 1;
}

.seasonal .fish-menu{
  background-color: #ffffff;
  padding:  20px;
}
.seasonal .seafish h5{
  font-size: 25px;
  color: #000000;
  font-weight: 300;
  text-align: left;
  width: 100%;
  
}
.seasonal .seafish p{
  font-size: 12px;
  color: #000000;
  width: 100%;
  
  
}
.seasonal .fish-menu ul{
  list-style: none;
 
}
.seasonal .fish-menu ul li{
  padding: 5px 0;
}

.menu-name{
  flex: none;
}




  
@media screen and (max-width: 768px) {
  .seasonal .seafish img{
     width: 130px;
     height: 76px;
  }

}



@media only screen and (max-width: 600px){
  .seasonal .barbeque{
      width: 100%;
  }
}

@media screen and (max-width: 474px) {
  .seasonal .seafish h5 {
    width: 150px;
}
}

@media (min-width: 769px) and (max-width: 992px) {
  .seasonal .seafish h5 {
   
    max-width: 150px; 
  }
}
