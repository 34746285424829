.time{
    padding: 20px 0;
    background-color: #000;
  }
  .time h5{
    color: #ffffff;
    text-transform: uppercase;
    font-size: 25px;
    font-weight: 400;
  }
  .time ul{
    list-style: none;
  }
  .time ul li p{
    color: #ffffff;
    font-size: 20px;
    margin: 0;
  }
  .time ul li{
    margin: 20px 0;
  }