.contact{
    padding: 50px 0;
     text-transform: uppercase;
  }
  
  .contact .message input{
    border: 1px solid  #bfbfbf;
    width: 100%;
    margin-bottom: 10px;
    padding: 8px;
    font-size: 14px;
    
  }
  .contact .message textarea{
    width: 100%;
    border: 1px solid  #bfbfbf;
    padding: 8px;
    font-size: 14px;
    background-color: rgba(255, 255, 255, 0.2);
    margin-bottom: 20px;
  }
  
  .contact .message .button9{
    padding: 10px 35px;
   background-color: #000;
    text-transform: uppercase;
    font-size: 16px;
    display: inline-block;
    font-family: inherit;
    color: #fff;
  }
  .contact .map img{
    width: 100%;
    display: flex;
    
  }
.mapiframe{
  width: 100%;
}
  

/* Custom loading spinner */
