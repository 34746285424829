

 .about{
    padding: 50px 0;
    background-color: #a9b283;
    margin: 0 auto;
  }
  .about img{
    padding-bottom: 20px;
    width: 158px;
  }
  .about h3{
    font-size: 33px;
    font-weight: bold;
  }
  .about p{
    font-size: 16px;
    width: 55%;
    margin: 0 auto;
    padding-bottom: 20px;
    font-weight: 500;
  }
  .button2 {
    padding: 10px 35px;
    border: 2px solid #000000;
    position: relative;
    overflow: hidden;
    background-color: transparent;
    text-align: center;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 600;
    transition: .3s;
    z-index: 1;
    font-family: inherit;
    color: #000000;
    text-decoration: none;
   }

   .container-fluid{
    overflow: hidden;
   }

 .button2:hover{
  background-color: #000000;
  color: white;
 }

