.aboutus {
  background-color: #000000;
  padding: 5vw; /* Using vw for padding to make it responsive */
  padding-bottom: 10vw; /* Using vw for padding to make it responsive */
}

.aboutus .section2 h3 {
  font-size: 3.5vw; /* Using vw for font size to make it responsive */
  font-weight: 200;
  color: #999999;
  padding-bottom: 2vw; /* Using vw for padding to make it responsive */
}

.aboutus .container {
  max-width: 1200px;
}

.aboutus .section2 p {
  color: #999999;
  font-size: 1.2em; /* Using em for font size to make it responsive */
}

.aboutus video {
  max-width: 100%;
  height: auto;
  padding-top: 5vw; /* Using vw for padding to make it responsive */
}

.video-container {
  position: relative;
}

.play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: transparent;
  border: none;
  font-size: 2vw; /* Using vw for font size to make it responsive */
  color: aliceblue;
  cursor: pointer;
  width: 30vw; /* Using vw for width to make it responsive */
  min-width: 200px; /* Set a minimum width to ensure responsiveness */
}

#texti{
  font-size: 34px;
  color: #ded94a;
}