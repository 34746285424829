@font-face {
  font-family: 'Aileron-Light';
  src: url('../../../../../public/Aileron-Light.otf') format('opentype');
 
}

.A-Light2{
  font-family: 'Aileron-Light', sans-serif;
}




.footer{
    background-color: #000000;
    padding: 20px 0;
  }
  .footer p{
   color: #fff;
   margin: 0;
   font-size: 16px;
  }



