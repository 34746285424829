.heading1{
    padding: 50px 0;
    background-color: #000000;
  }
  .heading1 h3{
    font-size: 40px;
    color: #ffffff;
    font-weight: 200;
    
  }
  .juiceimage{
    height: 100px;
    width: 100px;
  }
  
  
  /* juices */
  .juices{
    background-color: #000000;
  }

  .juice-content{
    padding: 35px 0;
    background-color: #171717;
    margin: 0px 6%;
  }
  
  .juices ul{
    list-style: none;
  }
  .juices ul li{
    padding: 20px 0;
  }
  .juices ul li .item{
    display: flex;
    align-items: center;
  }
  .juices .item h5{
    color: #fff;
    font-size: 20px;
    font-weight: 300;
    padding: 0 20px;
    text-align: left;
    width: 80%;
  }
  .juices .item .line{
    height: 1px;
    width: 50%;
    background-color: #585858;
  }
  .juices .item p{
    color: #d99448;
    font-size: 16px;
    padding: 0 20px;
    width: 30%;
    text-align: right;
    margin-bottom: 0;
  }

  @media screen and (max-width: 768px) {
   
  /* .juice-content{
    padding: 50px 0;

    margin: 0px 6%;
  } */
  
  }