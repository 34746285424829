.service {
  background-color: #c5c4b1;
  padding: 50px 0;
}

.service .section1 h5 {
  color: #000000;
  font-size: 25px; /* Font size for normal screens */
  font-weight: 500;
}

.service .section1 h3 {
  font-size: 50px; /* Font size for normal screens */
  color: #000000;
  padding-top: 20px;
  word-wrap: break-word; /* Ensures long words break into multiple lines */
}

.service .section1 p {
  font-size: 18px; /* Font size for normal screens */
  color: #000000;
}

.service .section1 h6 {
  font-size: 20px; /* Font size for normal screens */
  margin: 0;
}

/* Responsive styles */
@media (max-width: 768px) {
  .service .section1 h5 {
    font-size: 20px; /* Decreased font size for smaller screens */
  }
  
  .service .section1 h3 {
    font-size: 32px; /* Decreased font size for smaller screens */
  }
  
  .service .section1 p {
    font-size: 16px; /* Decreased font size for smaller screens */
  }
  
  .service .section1 h6 {
    font-size: 18px; /* Decreased font size for smaller screens */
  }
}
