
#keydishes{
    background: url("../../../../public/images/keydishes\ image.jpg");
    background-repeat: no-repeat;
    width: 100%;
    background-position: top;
    background-size: contain;
  }

  #sliderkeyshes{
  height: 553px;
  width: 100%;
  background-position: center center;
  background-size: cover;
  }

.banner .texts{
    display: inline-flex;
    align-items: center;
    min-height: calc(600px - 72px);
    color: #fff;
    text-align: center;
    width: 100%;
    justify-content: center;
}
.banner img{
 width: 100%;
}
  

button {
    padding: 10px 35px;
    border: 2px solid #fff;
    background-color: transparent;
    text-transform: uppercase;
    font-size: 16px;
    display: inline-block;
    font-family: inherit;
    color: #fff;
   }
   .banner p{
    font-size: 25px;
    text-transform: uppercase;
    margin: 0;
   }
   .banner h3{
    font-size: 60px;
    padding-bottom: 20px;
    color: #ffffff;
    font-weight: 200;
   }


   @media screen and (max-width: 768px) {
    .banner .texts{
        top: 49%;
        min-height: 50vh; 
    }
    #sliderkeyshes{
        height: 550px;
        width: 100%;
      }
      #keydishes{
        background-repeat: no-repeat;
        width: 100%;
        background-position: top;
        background-size: cover;
      }
    
   }

   @media screen and (max-width: 1268px) {
    #keydishes{
        background-repeat: no-repeat;
        width: 100%;
        background-position: top;
        background-size: cover;
      }
   }



   