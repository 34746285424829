

.button3 {
    padding: 10px 35px;
    border: 2px solid #ffffff;
    position: relative;
    overflow: hidden;
    background-color: transparent;
    text-align: center;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 600;
    transition: .3s;
    z-index: 1;
    /* font-family: inherit; */
    color: #ffffff;
    text-decoration: none;
}



.juiceContent {
    display: flex;
    flex-direction:row ;
    align-items: center;
    justify-content: space-between;
    
}

.juice img {
    width: 60px;
    height: 120px;
}

.category {
    padding: 50px 0;
    background-color: #000000;
  }
  
  .category h3 {
    color: #fff;
    font-size: 40px;
    padding-bottom: 20px;
  }
  
  .category p {
    color: #909090;
    font-size: 25px;
    /* font-family: emoji; */
    font-weight: 200;
    margin: 0;
  }
  .category h4 {
    color: #999999;
    margin: 0;
  }
  
  .category .juice {
    padding: 30px 20px;
    border: 1px solid #101010;
  }
  

  
  .juice-content {
    padding: 15px;
  }
  
  .juice p {
    font-size: 14px;
    color: #8c8c8c;
  }
  
  .juice h5 {
    color: #fff;
    font-size: 25px;
    font-weight: 300;
    
  }


  .juice h4{
    color: #bf9707;
    
  }

  .juiceContent{
    display: flex;
    flex-direction: row;
  }
  
  /* CSS for medium screens */
  @media (max-width: 768px) {


    .juicename {
     flex: 1;
    
  }

  .juice h5 {
      font-size: 20px;
      /* text-align: left;  */
      margin-left: 17%;
  }

    .category .juice {
      padding: 20px;
    }
  
    .juice-content {
      padding: 10px;
    }
  
    .category p {
      font-size: 18px;
    }
  }
  
  /* CSS for small screens */
  @media (max-width: 576px) {
    .category .juice {
      padding: 15px;
    }
  
    .juice-content {
      padding: 5px;
    }
  
    .category p {
      font-size: 16px;
    }
  }
  
 
  @media (max-width: 383px) {
    .juice h5 {
      color: #fff;
      font-size: 20px;
      font-weight: 300;
      
  }
}
  @media (max-width: 323px) {
    .juice h5 {
      color: #fff;
      font-size: 17px;
      font-weight: 300;
      
  }
}

  
  