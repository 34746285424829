

#banner2{
  background: url("../../../../public/images/aboutus\ banner2.jpg");
  background-repeat: no-repeat;
  width: 100%;
  background-position: top;
  
}

#sliderbanner{
  height: 523px;
  width: 100%;
  background-position: center center;
  background-size: cover;
  background-blend-mode: multiply; 
  overflow: hidden; 

}


  .banner .texts {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #fff;
    z-index: 1;
  }
  
  .banner .texts h1 {
    font-size: 4vw;
    font-weight: 700;
    text-transform: uppercase;
  }
  
  .banner .texts p {
    font-size: 1.5vw;
    color: #fff;
    margin: 0;
    text-transform: uppercase;
  }
  
  #button2 {
    /* padding: 1.5vw 5vw;  */
    border: 2px solid #fff;
    background-color: transparent;
    text-align: center;
    text-transform: uppercase;
    font-size: 15px;
    transition: 0.3s;
    /* font-family: inherit; */
    color: #fff;
    width: 230px;
    height: 40px;
  }
  
  @media screen and (max-width: 768px) {
    .banner .texts {
      top: 52%; 
  }

    .banner .texts h1 {
      font-size: 6vw;
    }
    .banner .texts h3 {
      font-size: 6vw; /* Adjust font size */
      word-break: break-word; /* Break words if needed */
      white-space: normal; /* Allow text to wrap */
    }
    .banner .texts p {
      font-size: 2vw;
    }
    button {
      padding: 3vw 8vw;
      border: 1vw solid #fff;
      /* font-size: 2vw;  */
    }
  }
  
  .banner .texts img {
    max-width: 60%;
    margin-top: 150px;
    height: auto;
  }
  .banner{
    width: 100%;
    min-height: 600px;
    background-position: center;
    background-size: cover;
    background-blend-mode: multiply; 
     min-height: calc(600px - 72px);
}
.banner .texts{
    display: inline-flex;
    align-items: center;
    min-height: calc(600px - 72px);
    color: #fff;
    text-align: center;
    width: 100%;
    justify-content: center;
}
.banner img{
 width: 100%;
}
  

   .banner p{
    font-size: 25px;
    text-transform: upp;
    margin: 0;
   }
   .banner h3{
    font-size: 60px;
    padding-bottom: 20px;
    color: #c5c4b1;
   }


   .Arabian{
    font-weight: 900;
   }