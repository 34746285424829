/* 
      
.delivery .restuarent img{
    width: 100%;
    display: flex;
    filter: grayscale(100%);

  } */

 
  
  .contents{
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .delivery .contents h6{
    font-size: 25px;
    color: #000000;
  }
  
  .delivery .contents ul{
    list-style: none;
    margin: 20px;
  }
  .delivery .contents ul li p{
    margin: 0;
    margin: 5px 0;
  }
  
  .delivery .contents .button5{
    padding: 10px 35px;
    background-color: #000000;
    text-align: center;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 200;
    display: inline-block;
    /* font-family: inherit; */
    color: #ffffff;
    text-decoration: none;
    border: 2px solid #000000;
   
  }
  .delivery .contents .button6{
    padding: 10px 35px;
    border: 2px solid #000000;
    text-align: center;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 600;
    display: inline-block;
    /* font-family: inherit; */
    color: #000000;
    text-decoration: none;
    
  }
  .delivery .contents .lines{
    padding: 0px 100px;
  }
  
  .buttons{
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-bottom: 5px;
  }


  .delivery {
    height: 550px; /* Set the height of the delivery class */
  }
  
  .delivery .restuarent {
    /* width: 400px; */
    height: 550px;
    overflow: hidden; /* Ensure any overflow is hidden */
  }
  
  .delivery .restuarent img {
    width: 100%;
    height: 100%;
    object-fit: cover; /* This will make the image cover the container while maintaining its aspect ratio */
    display: block;
    filter: grayscale(100%);
  }
  
  #container-delivery {
    padding: 0px;
  }
  
  .delivery h4 {
    font-size: 35px;
    color: #000000;
    font-weight: 500;
    padding: 15px;
  }

  
  
  @media (max-width: 768px) {
.delivery{
  display: flex;
  flex-direction: column;
  height: auto;

}
  }