/* Slider2.css */

.banner {
  position: relative;
  background-color: black; 
  width: 100%;
  min-height: calc(600px - 72px);
  background-position: center;
  background-size: cover;
  background-blend-mode: multiply; 
  overflow: hidden;
}



#slidercontact{
  height: 520px;
  width: 100%;
  background-position: center center;
  background-size: cover;
}


#contactus{
  background: url("../../../../public/images/contactus.jpg");
  background-repeat: no-repeat;
  width: 100%;
  min-height: 600px;
  background-position: top;
  
}

.banner {
  position: relative;
  width: 100vw; 
  height: 100vh; 
  overflow: hidden;
}

.banner .texts {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #fff;
  z-index: 1;
}

.banner .texts h1 {
  font-size: 4vw;
  font-weight: 700;
  text-transform: uppercase;
}

.banner .texts p {
  font-size: 1.5vw;
  margin: 0;
  text-transform: uppercase;
}

.banner .texts img {
  max-width: 70%;
  margin-top: 150px;
  height: auto;
}

@media screen and (max-width: 768px) {

  #slidercontact{
    height: 550px;
    width: 100%;
  }
  #contactus{
    background-repeat: no-repeat;
    width: 100%;
    background-position: initial;
    background-size: cover;
  }

  .banner .texts h1 {
    font-size: 6vw;
  }

  .banner .texts {
    top: 70%; 
  }

  .banner .texts h1 {
    font-size: 6vw;
  }

  .banner .texts p {
    font-size: 2vw;
  }

  button {
    padding: 3vw 8vw;
    border: 1vw solid #fff;
    font-size: 3vw;
  }

  .banner p {
    font-size: 3vw; /* Adjust paragraph font size for smaller screens */
  }

  .banner h3 {
    font-size: 6vw; /* Adjust heading font size for smaller screens */
  }
}

.banner {
  width: 100%;
}

.banner .texts {
  display: inline-flex;
  align-items: center;
  min-height: calc(600px - 72px);
  color: #fff;
  text-align: center;
  width: 100%;
  justify-content: center;
}

.banner img {
  width: 100%;
}

button {
  padding: 10px 35px;
  border: 2px solid #fff;
  background-color: transparent;
  text-transform: uppercase;
  font-size: 16px;
  display: inline-block;
  font-family: inherit;
  color: #fff;
}

.banner p {
  font-size: 25px;
  text-transform: uppercase;
  margin: 0;
}

.banner h3 {
  font-size: 60px;
  padding-bottom: 20px;
  color: #c5c4b1;
}


@media screen and (max-width: 1305px) and (min-width: 768px) {
  #slidercontact{
    height: 550px;
    width: 100%;
  }
  #contactus{
    background-repeat: no-repeat;
    width: 100%;
    background-position: initial;
    background-size: cover;
  }

  .banner .texts h1 {
    font-size: 6vw;
  }

  .banner .texts {
    top: 70%; 
  }

  .banner .texts h1 {
    font-size: 6vw;
  }

  .banner .texts p {
    font-size: 2vw;
  }

  button {
    padding: 3vw 8vw;
    border: 1vw solid #fff;
    font-size: 3vw;
  }

  .banner p {
    font-size: 3vw; /* Adjust paragraph font size for smaller screens */
  }

  .banner h3 {
    font-size: 6vw; /* Adjust heading font size for smaller screens */
  }
}


