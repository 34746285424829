
.delivery2{
    padding: 50px 0;
    background-color: #fff;
  }
  .delivery2 h3{
    font-size: 40px;
    font-weight: 200;
    padding-bottom: 30px;
    color: #000000;
    
  }
  .delivery2 .location{
    margin-top: 30px;
    border-right: 1px solid #000;
  }
  .delivery2 .location ul{
    list-style: none;
  }
  .delivery2 .location ul li p{
    margin: 0;
    font-size: 16px;
  }
  .delivery2 .location h4{
    font-size: 30px;
    font-weight: 200;
    color: #000;
  }
  .delivery2 .location .button7{
    padding: 10px 35px;
    background-color: #000;
    border: 2px solid #000;
    text-transform: uppercase;
    font-size: 16px;
    display: inline-block;
    font-family: inherit;
    text-decoration: none;
    color: #fff;
    margin: 5px;
  }
  .delivery2 .location .button8{
    padding: 10px 35px;
    border: 2px solid #000;
    text-transform: uppercase;
    font-size: 16px;
    display: inline-block;
    font-family: inherit;
    text-decoration: none;
    color: #000;
    margin: 5px;
  }
  
  .delivery2 .fastest h4{
    font-size: 30px;
    font-weight: 200;
    color: #000;
  }
  .delivery2 .fastest p{
    font-size: 16px;
    color: #000;
  }
  
  
  
  #container-container{
    padding: 0px;
  }