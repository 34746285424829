.testimonials{
   position: relative; 
  background-color: #1b1b1c;
  padding: 40px 0;
}

.testimonials p{
  color: #bebcbc;
  font-size: 20px;
  text-align: center;
}

.testimonials h3{
  color: #fff;
  font-size: 40px;
}
.testimonial-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: end;
  gap: 2rem;
  background-color: #1b1b1c;
  color: #333;
  border-radius: 15px;
  margin: 20px auto;
  padding: 50px 80px;
  width: 800px;
  min-height: 430px;
  position: relative;
}


.testimonial {
  display: flex;
  align-items: center;
  text-align: center;
  height: 100%;
  line-height: 28px;
  margin: 0;
}

.user {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* gap: 10px; */
  color: #9a9898  ;
}


.user .user-details {
  text-align: center;
}

.user .username {
  margin: 0;
  font-size: 20px;
  color: #fff;
}

.user .role {
  margin: 0;
  font-size: 12px;
}

.progress-dots {
  display: flex;
  gap: 5px;
}

.progress-dot {
  width: 10px;
  height: 10px;
  border: 2px solid #fff;
  border-radius: 50%;
}

.progress-dot.active {
  background-color: #ffffff;
}

.btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 50px;
  cursor: pointer;
}

#btn-prev {
  left: 25px;
  color:#fff;
}

#btn-next {
  right: 25px;
  color: #fff;
}

.swiper-button-next{
  content: 'next';
  color: rgb(50, 49, 49);
 
}
.swiper-button-prev{
  content: 'next';
  color: rgb(50, 49, 49);
}

.swiper-container-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 4px;
  background-color: white;
}