/* menu.css */

.menu {
    padding: 50px 0;
    background-image: url("../../../../../public/images/menu.jpg");
    background-size: cover;
    background-position: center;
    color: #fff;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
   height: 100vh;
   /* background-size: contain; */
   /* background-repeat: no-repeat; */
   
  }
  
  .menu h3 {
    margin-bottom: 40px;
    font-size: 40px;
  }
  
  .menu p {
    font-size: 30px;
    font-weight: lighter;
   margin: 0;
    color: rgb(202, 202, 202);
  }
  
  .menu .text {
    font-size: 30px;
    padding: 30px 0;
    margin: 0;
  }
  
  .menu-image {
    width: 100%;
    height: auto;
    display: block;
  }

  

/* menu.css */

.custom-prev-button,
.custom-next-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  z-index: 1;
}


.custom-prev-button {
  left: -130px;
  font-size: 50px;
  top: 100px;
}

.custom-next-button {
  right: -130px;
  font-size: 50px;
  top: 100px;
}

.carousel-container {
  position: relative;
}

/* Media query for screens up to 866px */
@media (max-width: 866px) {
  .custom-prev-button,
  .custom-next-button {
    font-size: 40px;
  }
  
  .custom-prev-button {
    left: -100px; 
    top: 50px;
  }

  .custom-next-button {
    right: -100px; 
    top: 50px; 
  }
}

/* Media query for screens up to 576px */
@media (max-width: 576px) {
  .custom-prev-button,
  .custom-next-button {
    font-size: 30px; 
  }
  
  .custom-prev-button {
    left: -70px; 
    top: 30px;
  }

  .custom-next-button {
    right: -70px; 
    top: 30px; 
  }
}


