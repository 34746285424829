/* new css */

@media (max-width: 992px) {
  .juiceContent {
    flex-direction: column;
    align-items: center;
  }

  .order .fish img {
    width: 100%; 
    height: auto; 
  }

  .juiceContent .col-md-7, .juiceContent .col-md-5 {
    width: 100%; 
  }
}


@media (max-width: 576px) {
  .order .fish {
    padding: 15px;
  }

  .fish-content {
    padding: 5px;
  }

  .order p {
    font-size: 16px;
  }
}


/* end */

.order {
  padding: 50px 0;
  background-color: #000000;
}

.order h3 {
  color: #fff;
  font-size: 40px;
  padding-bottom: 20px;
  /* font-family: sans-serif; */
}

.order p {
  color: #a9b283;
  font-size: 20px;
  /* font-family: system-ui; */
  font-weight: 200;
  margin: 0;
}
.order h4 {
  font-size: 25px;
  color: #999999;
  margin: 0;
  /* font-family: monospace; */

}

.order .fish {
  padding: 30px 20px;
  border: 1px solid #101010;
}

.order .fish img {
  width: 230px;
  height: 140px;
}

.fish-content {
  padding: 15px;
}

.fish p {
  font-size: 14px;
  color: #8c8c8c;
}

.fish h5 {
  color: #fff;
  font-size: 25px;
  font-weight: 300;
}

/* CSS for medium screens */
@media (max-width: 768px) {
  .order .fish {
    padding: 20px;
  }

  .fish-content {
    padding: 10px;
  }

  .order p {
    font-size: 18px;
  }
}

/* CSS for small screens */
@media (max-width: 576px) {
  .order .fish {
    padding: 15px;
  }

  .fish-content {
    padding: 5px;
  }

  .order p {
    font-size: 16px; 
  }
  .fish h5 {
    margin-left: 13px;
}
}

.juiceContent{
  display: flex;
  flex-direction: row;
}



@media (max-width: 1400px)  { 
.order .fish img {
width: 200px;
}

}

/* CSS for screen sizes between 769px and 1200px */
@media (max-width: 1200px) and (min-width: 993px) {

  
  .juiceContent {
    flex-wrap: wrap;
  }

  .order .fish {
    width: 50%;
    padding: 20px;
  }
}

@media (max-width: 992px) and (min-width: 769px) {
  .juiceContent {
    flex-wrap: wrap;
    
  }

  .juiceContent .h5{
   text-align: center;
  }

}




@media (max-width: 333px) {
  
.fish h5 {
  color: #fff;
  font-size: 20px;
  font-weight: 300;
}
}